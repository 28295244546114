import Vue from 'vue'
import Vuex from 'vuex'
import {
  getSupportRequests,
  getSupportRequestTypes,
  getSettings,
  getPartnerDetails,
} from '@/services/http'
import {
  GET_SUPPORT_REQUESTS,
  GET_SUPPORT_REQUEST_TYPES,
  FETCH_SETTINGS,
  GET_SEND_US_MESSAGE_ACCESS,
} from './actions.types'
import {
  SET_SUPPORT_REQUESTS,
  SET_SUPPORT_REQUEST_TYPES,
  SET_USER,
  SET_SETTINGS,
  SET_HAS_ACCESS_SEND_US_MESSAGE,
} from './mutations.types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    supportRequests: {
      paging: {},
      requests: [],
    },
    supportRequestTypes: [],
    settings: {},
    hasAccessSendUsMessage: true,
  },
  mutations: {
    [SET_SUPPORT_REQUESTS](state, requests) {
      state.supportRequests = { ...requests }
    },
    [SET_SUPPORT_REQUEST_TYPES](state, types) {
      state.supportRequestTypes = types
    },
    [SET_USER](state, user) {
      state.user = user
    },
    [SET_SETTINGS](state, settings) {
      state.settings = settings
    },
    [SET_HAS_ACCESS_SEND_US_MESSAGE](state, data) {
      state.hasAccessSendUsMessage = data
    },
  },
  actions: {
    async [GET_SUPPORT_REQUESTS]({ commit }, params) {
      const response = await getSupportRequests(params)
      commit(SET_SUPPORT_REQUESTS, response.data)
    },
    async [GET_SUPPORT_REQUEST_TYPES]({ commit }) {
      const response = await getSupportRequestTypes()
      commit(SET_SUPPORT_REQUEST_TYPES, response.data)
    },
    async [FETCH_SETTINGS]({ state, commit }, settings) {
      const params = settings.join(',')
      const response = await getSettings(params)
      commit(SET_SETTINGS, { ...state.settings, ...response.data?.settings })
    },

    async [GET_SEND_US_MESSAGE_ACCESS]({ commit }) {
      const response = await getPartnerDetails()

      // Failed to fetch data
      if (!response.data) return

      const hasAccess =
        !response.data.storeFrontType || // the field is not passed - Contact us
        response.data.storeFrontType !== 'Restaurant' // partner is grocery - Contact us

      commit(SET_HAS_ACCESS_SEND_US_MESSAGE, hasAccess)
    },
  },
})
